import { SerializedStyles } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import tw, { css, TwStyle } from "twin.macro";

import { randStr } from "../utils/common";
import { PageLoader } from "./PageLoader";

interface Props {
  form: any;
  handleCaptchaInput: any;
  inputStyles: Array<SerializedStyles | TwStyle> | SerializedStyles | TwStyle;
}

const Captcha: React.FC<Props> = ({ form, handleCaptchaInput, inputStyles, ...rest }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [captcha, setCaptcha] = useState("");

  const captchaUsername = "totavo";

  useEffect(() => {
    setCaptcha(randStr(16)); // To have a new captcha on each reload
  }, []);

  // TODO: Change how captcha is added to the form
  form.captcha = captcha;

  return (
    <div tw="grid grid-cols-4" {...rest}>
      <div
        css={[
          css`
            ${tw`flex w-36`}
          `,
          loading ? tw`block` : tw`hidden`
        ]}
      >
        <PageLoader height={40} width={40} />
      </div>
      <div css={[tw`col-span-4 sm:col-span-2 mx-auto w-full sm:mr-2`, loading ? tw`hidden` : tw``]}>
        <img
          aria-label="captcha"
          alt="CAPTCHA"
          src={"http://image.captchas.net?client=" + captchaUsername + "&random=" + captcha + "&height=75"}
          onLoad={() => setLoading(false)}
          css={tw`w-96 mx-auto`}
        />
      </div>
      <div css={tw`col-span-4 sm:col-span-2 flex w-full ml-1 pr-1 mt-4 sm:mt-0`}>
        <input
          type="text"
          css={inputStyles}
          placeholder={t("contactUs.captcha")}
          defaultValue={form.captchaInput}
          onInput={handleCaptchaInput}
          required={true}
        />
      </div>
    </div>
  );
};

export default Captcha;
