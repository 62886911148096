import { css } from "twin.macro";

interface Props {
  label: any;
  onInput: any;
  id: string;
  name: string;
  value?: string;
}

const containerStyles = css`
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: block;
  position: relative;
  padding-top: 7px;
  margin-bottom: 12px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ .checkmark {
    background-color: #07575b;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 8px;
    top: 4px;
    width: 0.5rem;
    height: 0.8rem;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .checkboxLabel {
    margin-left: 35px;
  }
`;
const checkmark = css`
  position: absolute;
  border: 1px solid #07575b;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  margin-left: 5px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const Checkbox: React.FC<Props> = ({ label, onInput, id, name, value }) => {
  return (
    <div>
      <label className="container" css={containerStyles}>
        <input name={name || "checkbox"} id={id || "checkbox"} onInput={onInput} type="checkbox" required={true} value={value || ""} />
        <span className="checkmark" css={checkmark} />
        <span className="checkboxLabel">{label}</span>
      </label>
    </div>
  );
};
