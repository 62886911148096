import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { FormEvent, useEffect, useState } from "react";
import tw, { css } from "twin.macro";

import { ContactForm } from "../interfaces/contact_form";
import { randStr } from "../utils/common";
import AgreeCheckbox from "./AgreeCheckbox";
import Captcha from "./Captcha";
import { Container } from "./Container";
import SingleSelect from "./SingleSelect";

interface Props {
  form: ContactForm;
  setForm: (value: ContactForm) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  status: {
    error?: boolean;
    success?: boolean;
  };
  freeReport?: boolean;
  freeReportUrl?: string | null;
}

const Root = tw.section`bg-grey-light text-center py-3 shadow-md`;
const labelStyle = css`
  label {
    ${tw`float-left`}
  }
`;
const inputStyle = css`
  ${tw`bg-white p-3 w-full border-grey border`}
  border-radius: 4px;
  padding: 0.7rem;
  &:hover: {
    border-color: rgb(200, 202, 206);
  }
`;

export const ContactUsForm: React.FC<Props> = ({ form, setForm, handleSubmit, status, freeReport, freeReportUrl }) => {
  const { t } = useTranslation();
  const [captcha, setCaptcha] = useState("");
  const [selectedOption, setSelectedOption] = useState<null | { value: string; label: string }>(null);
  const SelectOptions = [
    { value: "Material processing", label: t("contactUs.select.materialProcessing") },
    { value: "Scale up", label: t("contactUs.select.scaleUp") },
    { value: "New material", label: t("contactUs.select.newMaterial") },
    { value: "Expert", label: t("contactUs.select.expert") },
    { value: "Leads", label: t("contactUs.select.leads") },
    { value: "Leads world wide", label: t("contactUs.select.leadsWorldWide") },
    { value: "Other", label: t("contactUs.select.other") }
  ];
  if (freeReport) {
    SelectOptions.push({ value: "Free Report", label: t("I want my Free Report") });
  }

  const formCss = css`
    margin-top: 0px;
  `;

  useEffect(() => {
    setCaptcha(randStr(16)); // To have a new captcha on each reload
  }, []);

  // TODO: Change how captcha is added to the form
  form.captcha = captcha;
  const handleFirstName = (event: FormEvent<HTMLInputElement>) => handleForm("firstName", (event.target as HTMLInputElement).value);
  const handleLastName = (event: FormEvent<HTMLInputElement>) => handleForm("lastName", (event.target as HTMLInputElement).value);
  const handleEmail = (event: FormEvent<HTMLInputElement>) => handleForm("email", (event.target as HTMLInputElement).value);
  const handleMessage = (event: FormEvent<HTMLTextAreaElement>) => handleForm("message", (event.target as HTMLTextAreaElement).value);
  const handleCaptchaInput = (event: FormEvent<HTMLInputElement>) => handleForm("captchaInput", (event.target as HTMLInputElement).value);
  const handleContactReason = () => handleForm("contactReason", selectedOption ? selectedOption.value : "3");
  const handleAgreement = (event: FormEvent<HTMLInputElement>) => handleForm("agreement", (event.target as HTMLInputElement).value);
  const handlePhoneNumber = (event: FormEvent<HTMLInputElement>) => handleForm("phoneNumber", (event.target as HTMLInputElement).value);
  const handleForm = (key: keyof ContactForm, value: string) =>
    setForm({
      ...form,
      [key]: value
    });

  if (status.success) {
    if (freeReport && freeReportUrl) {
      window.open(freeReportUrl, "_blank");
    }
    navigate("/thank-you");
  }

  return (
    <Root>
      <Container>
        <form
          name="contact-us-form"
          tw="max-w-2xl m-auto mt-12 w-full grid grid-cols-3 gap-4"
          css={freeReport ? formCss : ""}
          onSubmit={handleSubmit}
        >
          <div tw="col-span-3" css={labelStyle}>
            <label>{t("contactUs.contactReason")}</label>
            <br />
            <SingleSelect
              onChange={setSelectedOption}
              onInputChange={handleContactReason}
              options={SelectOptions}
              css={inputStyle}
              isClearable={false}
              isSearchable={false}
              placeholder={t("contactUs.select.placeholder")}
            />
          </div>
          <div tw="col-span-3" css={labelStyle}>
            <label htmlFor="firstName">{t("commonForm.firstName")}</label>
            <input
              name="firstName"
              type="text"
              css={inputStyle}
              placeholder={t("commonForm.firstName")}
              defaultValue={form.firstName}
              onInput={handleFirstName}
              required={true}
            />
          </div>
          <div tw="col-span-3" css={labelStyle}>
            <label htmlFor="lastName">{t("commonForm.lastName")}</label>
            <input
              name="lastName"
              type="text"
              css={inputStyle}
              placeholder={t("commonForm.lastName")}
              defaultValue={form.lastName}
              onInput={handleLastName}
              required={true}
            />
          </div>
          <div tw="col-span-3" css={labelStyle}>
            <label htmlFor="email">{t("commonForm.email")}</label>
            <input
              name="email"
              type="email"
              css={inputStyle}
              placeholder={t("commonForm.email")}
              defaultValue={form.email}
              onInput={handleEmail}
              required={true}
            />
          </div>
          <div tw="col-span-3" css={labelStyle}>
            <label htmlFor="phoneNumber">{t("commonForm.phoneNumber")}</label>
            <input
              name="phoneNumber"
              type="tel"
              css={inputStyle}
              pattern="[0-9\s\-\+]{0,17}$"
              placeholder={t("commonForm.phoneNumber")}
              defaultValue={form.phoneNumber}
              onInput={handlePhoneNumber}
              required={true}
            />
          </div>
          <div tw="col-span-3" css={labelStyle}>
            <label htmlFor="message">{t("contactUs.message")}</label>
            <textarea
              name="message"
              css={inputStyle}
              placeholder={t("contactUs.message")}
              defaultValue={form.message}
              onInput={handleMessage}
            />
          </div>
          <Captcha form={form} handleCaptchaInput={handleCaptchaInput} inputStyles={inputStyle} tw="col-span-3 w-full grid grid-cols-4" />
          <div tw="col-span-3">
            <AgreeCheckbox handleAgreement={handleAgreement} name="agreement" />
          </div>
          <div tw="col-span-3">
            <button tw="py-2 px-4 font-bold bg-red text-white hover:bg-red-dark" type="submit">
              {t("commonForm.submit")}
            </button>
            {status.error && <p tw="text-red-light mt-2">{t("commonForm.error")}</p>}
            <a href="tel:+4982140821442" tw="py-6 block">
              {t("contactUs.contactPhone")} +49 821 4082 1442
            </a>
          </div>
        </form>
      </Container>
    </Root>
  );
};
