import { Link } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import tw from "twin.macro";

import { Checkbox } from "./Checkbox";

interface Props {
  handleAgreement?: any;
  id?: string;
  name: string;
  value?: string;
}

const Span = tw.span`text-center py-3`;

const AgreeCheckbox: React.FC<Props> = ({ handleAgreement, id, name, value, ...rest }) => {
  const { i18n } = useTranslation();
  return (
    <Span>
      <Checkbox
        name={name || "agreement"}
        id={id || "agreement"}
        value={value}
        onInput={handleAgreement}
        label={
          <Trans i18nKey="commonForm.agreement" tw="text-xs">
            I agree to the
            <Link to={`/disclaimers/${i18n.language}/terms-of-use`} tw="font-semibold no-underline hover:underline">
              Terms of Use
            </Link>
            and
            <Link to={`/disclaimers/${i18n.language}/privacy-policy`} tw="font-semibold no-underline hover:underline">
              Privacy Policy
            </Link>
          </Trans>
        }
        {...rest}
      />
      <label tw="w-full" htmlFor={id || "agreement"} className={"error"} />
    </Span>
  );
};

export default AgreeCheckbox;
