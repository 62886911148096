import React, { Fragment } from "react";
import Select from "react-select";

interface Props {
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  options: SelectOptions[];
  onChange?: any;
  defaultValue?: any;
  onInputChange?: any;
  placeholder?: string;
}

interface SelectOptions {
  value: string;
  label: string;
}

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 50,
    minHeight: 50,
    padding: 6,
    borderColor: "rgb(200, 202, 206)",
    boxShadow: "none",
    "&:hover": {
      borderColor: "rgb(200, 202, 206)"
    }
  })
};

const SingleSelect: React.FC<Props> = ({
  isClearable,
  isDisabled,
  isLoading,
  isRtl,
  isSearchable,
  options,
  onChange,
  onInputChange,
  defaultValue,
  placeholder
}) => {
  return (
    <Fragment>
      <Select
        placeholder={placeholder || "Select..."}
        defaultValue={defaultValue}
        className="basic-single"
        classNamePrefix="select"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="contactReason"
        options={options}
        styles={customStyles}
        onChange={onChange}
        onInputChange={onInputChange}
      />
    </Fragment>
  );
};

export default SingleSelect;
